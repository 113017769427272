import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Button, Icon, Modal, Segment } from 'semantic-ui-react'
import { useIntl, FormattedMessage } from "react-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import devicesImg from "../images/devices.png"
import managementImg from "../images/example-management-elections-min.png"
import "./index.css"
import styled from "styled-components"

const Card = styled.div`
  padding: 10px !important;
  min-height: 180px !important;
`

const Divider = styled.div`
  margin: 50px 0 !important;
`
function LayoutedIndexPage({title, description, showCallToAction}) {
  const intl = useIntl()

  if (title === null) {
    title = intl.formatMessage({id: 'index.defaultProps.title'})
  }
  if (description === null) {
    description = intl.formatMessage({id: 'index.defaultProps.description'})
  }

  return (
    <>
      <SEO
        title={intl.formatMessage({id: 'layout.headerTitle'})}
        keywords={[
          `nvotes`, `secure`, `online`, `voting`, `tools`, `software`, 
          `system`, `election`, `poll`
        ]}
      />

      <div className="ui vertically divided centered middle aligned stackable grid container">
        <div className="two column row home-main-segment">
          <div className="column">
            <Segment vertical>
                <h1>{title}</h1>
                <p>{description}</p>
                {showCallToAction &&
                  <Link to="/contact">
                    <Button animated="fade" color="blue" size="huge">
                      <Button.Content visible>
                        <FormattedMessage id="index.helpOrganize" />
                      </Button.Content>
                      <Button.Content hidden>
                        <FormattedMessage id="index.helpOrganize" /> &nbsp;
                        <Icon name='arrow right' />
                      </Button.Content>
                    </Button>
                  </Link>
                }
              </Segment>
          </div>
          <div className="column">
              <img src={devicesImg} alt="Online Management Software" />
          </div>
        </div>

          <div className="ui vertically divided centered middle aligned stackable grid container">
            <div className="four column row">
              <div className="column">
                  <div className="ui blue statistic">
                    <div className="value">
                      +2M
                    </div>
                    <div className="label">
                      <FormattedMessage id="index.votesCast" />
                    </div>
                  </div>
              </div>
              <div className="column">
              <div className="ui blue statistic">
                <div className="value">
                  +150K
                </div>
                <div className="label">
                  <FormattedMessage id="index.votesIn1Election" />
                </div>
              </div>
            </div>
              <div className="column">
                <div className="ui blue statistic">
                  <div className="value">
                    +150
                  </div>
                  <div className="label">
                    <FormattedMessage id="index.customers" />
                  </div>
                </div>
              </div>
              <div className="column">
                <div className="ui blue statistic">
                  <div className="value">
                    +700
                  </div>
                  <div className="label">
                    <FormattedMessage id="index.simultaneousElections" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        <div className="two column row home-data-row">
          <div className="column">
            <div className="ui raised segment" style={{padding: 0}}>
              <iframe
                title={intl.formatMessage({id: 'index.smoothExperience.title'})}
                src="https://www.youtube.com/embed/nAZWCpI4gA4?rel=0"
                width="100%"
                height="280px"
                style={{margin: 0, border: 0}}
                allowFullScreen="allowFullScreen">
              </iframe>
            </div>
          </div>
          <div className="column">
            <h2>
              <FormattedMessage id="index.smoothExperience.title" />
            </h2>
            <p>
              <FormattedMessage id="index.smoothExperience.description" />
            </p>
              <Modal closeIcon trigger={
                <Button animated="fade" basic color="blue" size="big">
                  <Button.Content visible>
                    <FormattedMessage id="index.viewVideo" />
                  </Button.Content>
                  <Button.Content hidden>
                    <FormattedMessage id="index.viewVideo" /> &nbsp;
                    <Icon name='arrow right' />
                  </Button.Content>
                </Button>
              }>
              <Modal.Content style={{padding: 0}}>
                <Modal.Description>
                  <iframe
                    title={intl.formatMessage({id: 'index.smoothExperience.title'})}
                    src="https://www.youtube.com/embed/nAZWCpI4gA4?autoplay=1&rel=0&enablejsapi=true"
                    width="100%"
                    height="600px"
                    frameborder="0"
                    style={{margin: 0, border: 0}}
                    allowFullScreen="allowFullScreen">
                  </iframe>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </div>
        </div>
        <div className="two column row home-data-row">
          <div className="column">
            <h2>
              <FormattedMessage id="index.electionAdmin.title" />
            </h2>
            <p>
              <FormattedMessage id="index.electionAdmin.description" />
            </p>
          </div>
          <div className="column">
            <div className="ui raised segment">
              <Modal closeIcon trigger={
                <img 
                  src={managementImg} 
                  alt={intl.formatMessage({id: 'index.electionAdmin.altImg'})}
                  className="ui image" 
                />
              }>
              <Modal.Content>
                <Modal.Description>
                  <img 
                    src={managementImg} 
                    alt={intl.formatMessage({id: 'index.electionAdmin.altImg'})}
                    className="ui image massive" 
                  />
                </Modal.Description>
              </Modal.Content>
            </Modal>
            </div>
          </div>
        </div>
      </div>
      <Divider className="ui divider"></Divider>
      <div className="ui centered middle aligned stackable grid container home-data-row">
        <h2>
          <FormattedMessage id="index.ourCustomers.title" />
        </h2>

        <p>
          <FormattedMessage id="index.ourCustomers.usedAllOverTheWorld" /> &nbsp;
          <Link to="/customers/">
            <FormattedMessage id="index.ourCustomers.references" />
          </Link>
        </p>
        <div className="ui three column row">
          <div className="column">
            <Card className="ui fluid card">
              <div className="content">
                <div className="header">
                  <h3>
                    <FormattedMessage id="index.publicAdmins.title" />
                  </h3>
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage id="index.publicAdmins.description" />
                  </p>
                </div>
              </div>
            </Card>
          </div>
          <div className="column">
            <Card className="ui fluid card">
              <div className="content">
                <div className="header">
                  <h3>
                    <FormattedMessage id="index.politicalParties.title" />
                  </h3>
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage id="index.politicalParties.description" />
                  </p>
                </div>
              </div>
            </Card>
          </div>
          <div className="column">
            <Card className="ui fluid card">
              <div className="content">
                <div className="header">
                  <h3>
                    <FormattedMessage id="index.otherOrgs.title" />
                  </h3>
                </div>
                <div className="description">
                  <p>
                    <FormattedMessage id="index.otherOrgs.description" />
                  </p>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div className="ui hidden divider"></div>
      <div className="ui hidden divider"></div>
    </>
  )
}

function IndexPage({title, description, showCallToAction}) {
  // LayoutedIndexPage is another function because Layout declares the 
  // IntlProvider and only inside the layout can it be used
  return (
    <Layout>
      <LayoutedIndexPage
        title={title}
        description={description}
        showCallToAction={showCallToAction}
      />
    </Layout>
  )
}

IndexPage.defaultProps = {
  title: null,
  description: null,
  showCallToAction: true
}

IndexPage.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showCallToAction: PropTypes.bool
}

export default IndexPage
